import {
  Box,
  Button,
  Container,
  Divider,
  Fab,
  Paper,
  Typography,
} from "@mui/material";
import { useEffect, useMemo, useState } from "react";
import { SwipeUpAlt } from "@mui/icons-material";
import { PumpInfo } from "../../components/Info";
import { FetchData } from "../../services/FetchData";
import { Colors } from "../../utils/Colors";
import { NotFound } from "../../components/NotFound";
import CircularProgress from "@mui/material/CircularProgress";
import { useNavigate, useParams } from "react-router-dom";
import { Header } from "../../components/Header";
import ListView from "../../components/historial/Listado";
import { Footer } from "../../components/Footer";
import { setUrl, sortDataBySerie } from "../../utils/common";
import { UploadFileComponent } from "../../components/UploadFileComponent";
import { SearchBox } from "../../components/SearchBox";
import AddInventory from "../../components/AddInventory";

export const Home = () => {
  const navigate = useNavigate();
  const { userId } = useParams();
  const objetCurrentUser = localStorage.getItem("currentUser");
  const currentUser = useMemo(
    () => JSON.parse(objetCurrentUser) || {},
    [objetCurrentUser],
  );

  const [serie, setSerie] = useState("");
  const [inventarios, setInventarios] = useState([]);
  const [exito, setExito] = useState(false);
  const [searched, setSearched] = useState(false);
  const [loading, setLoading] = useState(false); // Nuevo estado para la animación de carga
  const [showHistory, setShowHistory] = useState(false);
  const [showUploadExcel, setShowUploadExcel] = useState(false);
  const [isAdd, setIsAdd] = useState(false);
  const [showOnlyOwnInventories, setShowOnlyOwnInventories] = useState(false);
  const [allInventories, setAllInventories] = useState([]);

  //VARIABLES EL FETCH
  const [instituciones, setInstituciones] = useState(null);
  const [servicios, setServicios] = useState(null);
  const [usuarios, setUsuarios] = useState(null);
  const [inventariosPropios, setInventariosPropios] = useState([]);
  const [inventariosEditados, setInventariosEditados] = useState([]);
  const [loadingHistory, setLoadingHistory] = useState(false);

  useEffect(() => {
    console.log("pidiendo datos");
    if (
      !currentUser ||
      !currentUser.id ||
      !userId ||
      currentUser.id !== parseInt(userId)
    ) {
      navigate("/");
    }
  }, [currentUser, navigate, userId]);

  const obtenerInventarios = async (responsableId) => {
    setLoadingHistory(true);
    console.log("obteniendo inventarios propios");
    Promise.all([
      fetch(setUrl("inventario/editados")).then((response) => response.json()),
      fetch(setUrl(`inventario/responsable/${responsableId}`)).then(
        (response) => response.json(),
      ),
      fetch(setUrl("inventario")).then((response) => response.json()),
    ])
      .then(([inventariosEditados, inventariosData, allInventarios]) => {
        const uniqueInstitutions = [
          ...new Set(inventariosData.map((item) => item.institucion)),
        ];
        const inventoryByInstitucions = allInventarios.filter((item) =>
          uniqueInstitutions.includes(item.institucion),
        );
        setInventariosPropios(inventariosData);
        setAllInventories(inventoryByInstitucions);
        setInventariosEditados(inventariosEditados);
        setLoadingHistory(false);
      })
      .catch((error) => console.error(error));
  };

  useEffect(() => {
    obtenerInventarios(currentUser.id);
  }, [currentUser.id]);

  //peticion de datos para el autocompletado

  useEffect(() => {
    Promise.all([
      fetch(setUrl("inventario/instituciones")).then((response) =>
        response.json(),
      ),
      fetch(setUrl("servicios")).then((response) => response.json()),
      fetch(setUrl("usuarios")).then((response) => response.json()),
    ])
      .then(([institucionesData, serviciosData, usuariosData]) => {
        const instWId = institucionesData.map((item, index) => {
          return {
            id: index + 1,
            ...item,
          };
        });
        setInstituciones(instWId);
        setServicios(serviciosData);
        setUsuarios(usuariosData);
      })
      .catch((error) => console.error(error));
  }, []);

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!serie) return;

    setLoading(true); // Iniciar la animación de carga
    setSearched(true);
    try {
      const data = await FetchData(serie);
      if (data && data.length > 0) {
        setInventarios(sortDataBySerie(data));
        setExito(true);
      } else {
        console.error("No se encontraron datos para la serie:", serie);
        resetData();
        setExito(false);
      }
    } catch (error) {
      console.error("Error al buscar datos:", error);
      resetData();
      setExito(false);
    }
    setLoading(false); // Detener la animación de carga
  };

  const resetData = () => {
    setInventarios([]);
  };

  const irArriba = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth", // si quieres un desplazamiento suave
    });
  };

  const handleAutoSearch = (seriEdit) => {
    setSerie(seriEdit.replace("C", ""));
    irArriba();
  };

  return (
    <Box
      display="flex"
      justifyContent="center"
      alignItems="flex-start"
      paddingBottom="3rem"
      sx={{
        minHeight: "100vh",
        background: Colors.primaryBack,
      }}
    >
      <Header
        user={currentUser}
        navigate={navigate}
        data={inventariosPropios}
        showHistory={showHistory}
        setShowHistory={setShowHistory}
        setShowUploadExcel={setShowUploadExcel}
        loadingHistory={loadingHistory}
      />
      <Container
        maxWidth="sm"
        sx={{
          display: "flex",
          justifyItems: "center",
          alignItems: "center",
          marginTop: "3rem",
          marginBottom: "1rem",
        }}
      >
        <Box
          display="flex"
          flexDirection="column"
          sx={{ width: "100%" }}
          gap="1rem"
        >
          {!isAdd && (
            <SearchBox
              serie={serie}
              setSerie={setSerie}
              setSearched={setSearched}
              handleSubmit={handleSubmit}
            />
          )}

          {loading ? (
            <Paper elevation={3} sx={{ width: "100%" }}>
              <Box
                display="flex"
                flexDirection="column"
                alignItems="center"
                justifyContent="center"
                gap="2rem"
                padding="2rem"
              >
                <CircularProgress sx={{ color: Colors.primary }} />
              </Box>
            </Paper>
          ) : (
            searched &&
            (exito ? (
              <>
                {inventarios.map((inventario) => (
                  <Paper
                    key={inventario.id}
                    elevation={3}
                    sx={{ width: "100%" }}
                  >
                    <PumpInfo
                      data={inventario}
                      instituteOptions={instituciones}
                      serviceOptions={servicios}
                      setServiceOptions={setServicios}
                      usuarios={usuarios}
                      setSearched={setSearched}
                      handleSubmit={handleSubmit}
                      obtenerInventarios={obtenerInventarios}
                    />
                  </Paper>
                ))}
                <Box
                  display="flex"
                  justifyContent="space-between"
                  alignItems="center"
                  gap="1rem"
                >
                  <Typography>
                    ¿Los resultados no coinciden con su busqueda?
                  </Typography>
                  <Button
                    variant="contained"
                    color="warning"
                    onClick={(e) => {
                      setIsAdd(true);
                      setSearched(false);
                    }}
                  >
                    Agregar
                  </Button>
                </Box>
              </>
            ) : (
              <Paper elevation={3} sx={{ width: "100%" }}>
                <Box
                  display="flex"
                  flexDirection="column"
                  alignItems="center"
                  justifyContent="center"
                  gap="2rem"
                  padding="2rem"
                >
                  <Typography>
                    El numero de serie buscado no se encuentra registrado
                  </Typography>
                  <NotFound />
                  <Box
                    display="flex"
                    sx={{
                      width: "100%",
                      justifyContent: "end",
                    }}
                  >
                    <Button
                      variant="contained"
                      color="warning"
                      onClick={(e) => {
                        setIsAdd(true);
                        setSearched(false);
                      }}
                    >
                      Agregar
                    </Button>
                  </Box>
                </Box>
              </Paper>
            ))
          )}

          <Paper elevation={3} sx={{ width: "100%" }}>
            <Box
              display="flex"
              alignItems="center"
              flexDirection="column"
              justifyContent="center"
              sx={{
                width: "100%",
                paddingY: ".5rem",
              }}
            >
              <Typography>
                <strong>Bombas inventariadas: </strong>
                {inventariosPropios.length}
              </Typography>
              {showHistory && (
                <>
                  <Divider sx={{ width: "100%" }} />
                  <ListView
                    user={currentUser}
                    data={{
                      inventariosPropios: inventariosPropios,
                      allInventories: allInventories,
                    }}
                    inventariosEditados={inventariosEditados}
                    handleAutoSearch={handleAutoSearch}
                    showOnlyOwnInventories={showOnlyOwnInventories}
                    setShowOnlyOwnInventories={setShowOnlyOwnInventories}
                  />
                </>
              )}
            </Box>
          </Paper>

          {isAdd && (
            <AddInventory
              serie={serie}
              instituciones={instituciones}
              servicios={servicios}
              setServicios={setServicios}
              currentUser={currentUser}
              setIsAdd={setIsAdd}
              obtenerInventarios={obtenerInventarios}
            />
          )}

          {showUploadExcel && (
            <UploadFileComponent
              currentUser={currentUser}
              irArriba={irArriba}
              setSerie={setSerie}
              obtenerInventarios={obtenerInventarios}
              setShowUploadExcel={setShowUploadExcel}
            />
          )}
        </Box>
      </Container>
      <Fab
        color="primary"
        sx={{ position: "fixed", bottom: "20px", left: "20px" }}
        onClick={(e) => irArriba()}
      >
        <SwipeUpAlt />
      </Fab>
      <Footer />
    </Box>
  );
};
