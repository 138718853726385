import {
  FormControl,
  FormControlLabel,
  RadioGroup,
  Radio,
} from "@mui/material";
import { useState } from "react";

export const OptionSelected = ({ setCategory }) => {
  const [selectedValue, setSelectedValue] = useState(0);

  const handleRadioChange = (event) => {
    const value = parseInt(event.target.value); // Asegúrate de convertir a número
    setSelectedValue(value);
    setCategory(value);
  };

  const radios = [
    { id: 0, label: "Inventariador" },
    { id: 1, label: "Institución" },
  ];

  return (
    <FormControl component="fieldset">
      <RadioGroup row defaultValue={0}>
        {radios.map((radio) => (
          <FormControlLabel
            key={radio.id}
            labelPlacement="top"
            control={
              <Radio
                checked={selectedValue === radio.id}
                onChange={handleRadioChange}
                value={radio.id}
                name="radio-button"
              />
            }
            label={radio.label}
          />
        ))}
      </RadioGroup>
    </FormControl>
  );
};
